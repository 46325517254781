import request from '@/utils/request'
const preFixUrl = '/push'
/**
 * 商品管理 -- 商品列表
 * @param {*} data
 * @returns
 */
export const queryLifeServeGoods = (data) => {
  return request({
    url: `${preFixUrl}/merchandiseControl/queryLifeServeGoods`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const updateLifeServeGoodsState = (data) => {
  return request({
    url: `${preFixUrl}/merchandiseControl/updateLifeServeGoodsState`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const updateLifeServeGoodsHot = (data) => {
  return request({
    url: `${preFixUrl}/merchandiseControl/updateLifeServeGoodsHot`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const deleteLifeServeGoods = (data) => {
  return request({
    url: `${preFixUrl}/merchandiseControl/deleteLifeServeGoods`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}