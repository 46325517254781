<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>商品管理</span>
      </div>
      <el-form :inline="true" :model="searchForm" ref="searchRef">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            v-model="searchForm.goodsName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSubmit"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" @click="resetSearch('searchRef')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <div class="form-box">
        <el-button plain type="primary" icon="el-icon-plus" @click="addMan"
          >新增</el-button
        >
        <!-- <el-button icon="el-icon-download" plain type="primary" @click="onBatchExport"
          >导出</el-button
        > -->
        <!-- <el-button icon="el-icon-upload2" plain type="primary" @click="add"
          >导入</el-button
        > -->
      </div>
    </el-card>
    <el-card shadow="never" class="data-list">
      <div slot="header" class="clearfix">
        <span>商品列表</span>
      </div>
      <div class="table-box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" fixed> </el-table-column>
          <el-table-column prop="goodsNum" label="商品编号"> </el-table-column>
          <el-table-column prop="coverImage" label="商品封面" width="90" fixed>
            <template slot-scope="scope">
              <img
                class="bgimg"
                :src="scope.row.coverImageUrl ? scope.row.coverImageUrl : img1"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
          <el-table-column prop="sort" label="排序"> </el-table-column>
          <el-table-column prop="goodsType" label="商品类别">
            <template slot-scope="scope">
              {{
                scope.row.goodsType == 1
                  ? '服务'
                  : scope.row.goodsType == 2
                  ? '套餐'
                  : ''
              }}
            </template>
          </el-table-column>
          <el-table-column prop="monthlySaleVolume" label="月销量">
          </el-table-column>
          <el-table-column
            prop="unsubscribeRate"
            label="退订率"
            min-width="130"
          >
          </el-table-column>
          <el-table-column prop="createTime" label="上架时间" min-width="130">
          </el-table-column>
          <el-table-column prop="isState" label="商品状态" min-width="130">
            <template slot-scope="scope">
              <!-- {{
                scope.row.isState == 0
                  ? '下架'
                  : scope.row.isState == 1
                  ? '上架'
                  : ''
              }} -->
              <el-switch
                v-model="scope.row.isState"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="isStateChange(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="isHot" label="设为爆款" min-width="130">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isHot"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0"
                @change="isHotChange(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="userIdCard" label="设为爆款" min-width="130">
          </el-table-column> -->
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <!-- <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >设为爆款</el-button
              > -->
              <el-button type="text" size="small" @click="editChange(scope.row)"
                >编辑</el-button
              >
              <el-button
                @click="deletehandleClick(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="total < pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[6, 12, 24, 36, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!--  -->
    <!-- <addUser ref="adduser" v-if="showuser" @onSubmit="onSubmit" />
    <addUsers ref="addusers" v-if="showusers" @onSubmit="onSubmit" /> -->
  </div>
</template>
<script>
// import addUser from "./adduser";
// import addUsers from "./addusers";
import table2excel from 'js-table2excel'
import * as shopApi from '@/api/shop'
import * as orderApi from '@/api/order'
export default {
  data() {
    return {
      searchForm: {
        goodsName: '',
      },
      pageNum: 1,
      pageSize: 6,
      total: null,
      input: '',
      tableData: [],

      // showuser: false,
      // showusers: false,
      img1: require('/src/assets/images/user.png'),
    }
  },
  components: {
    // addUser, addUsers
  },
  methods: {
    editChange(row) {
      this.$router.push({
        path: '/shopaddOredit',
        query: {
          data: JSON.stringify(row),
          // id: res.data.data,
        },
      })
    },
    addMan() {
      this.$router.push({
        path: '/shopaddOredit',
        // query: {
        //   data: null,
        // },
      })
    },
    // add() {
    //   this.showusers = true;
    //   this.$nextTick(() => {
    //     this.$refs.addusers.init();
    //   });
    // },
    //支持导出图片和类型转换
    // async onBatchExport() {
    //   let params = {
    //     data: {
    //       ...this.searchForm,
    //       pageNum: 1,
    //       pageSize: this.total,
    //     },
    //   }
    //   let res = await userApi.userList(params)
    //   if (res.code == 200) {
    //     let tableLists = res.data.records

    //     tableLists.map((item) => {
    //       item.userSex =
    //         item.userSex == 1 ? '男' : item.userSex == 2 ? '女' : '未知'
    //     })
    //     const column = [
    //       {
    //         title: '用户头像',
    //         key: 'userPhoto',
    //         type: 'image',
    //         width: 50,
    //         height: 50,
    //       },
    //       { title: '用户姓名', key: 'userName', type: 'text' },
    //       { title: '用户性别', key: 'userSex', type: 'text' },
    //       { title: '用户手机号', key: 'userPhone', type: 'text' },
    //       { title: '用户身份证号', key: 'userIdCard', type: 'text' },
    //     ]
    //     table2excel(column, tableLists, '用户信息')
    //   }
    // },
    // async onDelete(id) {
    //   let params = {
    //     code: "",
    //     data: id,
    //     time: "",
    //   };
    //   let { data } = await this.$base.postAjax(
    //     "/mallUser/CustomerMan/deleteCustomerS",
    //     params
    //   );
    //   if (data.code == 200) {
    //     this.$message.success("删除成功");
    //     this.onSubmit();
    //   }
    // },
    handleSelectionChange() {},
    handleSizeChange(val) {
      this.pageNum = 1
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    //重置
    resetSearch(formName) {
      this.$refs[formName].resetFields()
      this.onSubmit()
    },
    //搜索
    onSubmit() {
      this.pageNum = 1
      this.getData()
    },
    //获取数据
    async getData() {
      let params = {
        // data: {
        ...this.searchForm,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // },
      }
      let res = await shopApi.queryLifeServeGoods(params)
      if (res.code == 200) {
        this.tableData = res.data.records
        this.total = res.data.total
        this.tableData.forEach((item) => {
          orderApi
            .getTYImageUrl({
              data: item.coverImage,
            })
            .then((res) => {
              item.coverImageto = res.data
            })
        })
      } else {
        this.$message.warning(res.msg)
      }
    },
    //监听排序
    sortChange() {},
    //表格数据
    ValueFormatter(a, b, c) {
      if (c && c != null && c != undefined && c != 'null' && c != 'undefined') {
        return c
      } else {
        return '-'
      }
    },
    isStateChange(row) {
      shopApi
        .updateLifeServeGoodsState({
          goodsNum: row.goodsNum,
          isState: row.isState,
        })
        .then((res) => {
          this.getData()
          this.$message({
            message: '操作成功',
            type: 'success',
          })
        })
    },
    isHotChange(row) {
      shopApi
        .updateLifeServeGoodsHot({
          goodsNum: row.goodsNum,
          isHot: row.isHot,
        })
        .then((res) => {
          this.getData()
          this.$message({
            message: '操作成功',
            type: 'success',
          })
        })
    },
    deletehandleClick(row) {
      this.$confirm('此操作将删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          shopApi
            .deleteLifeServeGoods({
              goodsNum: row.goodsNum,
            })
            .then((res) => {
              if (res.code == 200) {
                this.getData()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              } else {
                this.$message.warning(res.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
  created() {
    this.onSubmit()
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.bgimg {
  width: 60px;
  height: 60px;
}
</style>
